import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import en from "./locales/en.json";
import da from "./locales/da.json";

// react-i18n
const resources = {
    en: { translation: en },
    da: { translation: da },
};


// initiate detector when the locales are fully implemented
i18n.use(initReactI18next) // passes i18n down to react-i18next
    // .use(detector)
    .init({
        debug: false,
        resources,
        lng: "da", // language to use
        fallbackLng: "da", // fallback language to use, when a translation is not found in the current language
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
